import Button from '@mui/material/Button';
import { DEFINITION } from '../../../constants/constants';

interface IProps {
  label: string;
  onClickHandler?: () => void;
  disabled?: boolean;
  type?: typeof DEFINITION.BUTTON | typeof DEFINITION.SUBMIT | typeof DEFINITION.RESET;
}

function IButton(props: IProps) {
  const { label, onClickHandler, disabled, type } = props;

  return (
    <Button type={type} variant={DEFINITION.OUTLINED} disabled={disabled} onClick={onClickHandler ? onClickHandler : () => {}}>
      {label}
    </Button>
  );
}

export default IButton;
