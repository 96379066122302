export const ERRORS = {
  PROMISE_REJECTED: 'Failed to fetch data.',
  FETCHING_PEOPLE_FAILURE: 'Error fetching people data',
};

export const DEFINITION = {
  OUTLINED: 'outlined' as const,
  CENTER: 'center' as const,
  TABLE: 'table',
  CARDS: 'cards',
  SORT_BY: 'Sort By',
  DEFAULT: 'default',
  ASC_NAME: 'ascName',
  DES_NAME: 'desName',
  ASC_YEAR: 'ascYear',
  DES_YEAR: 'desYear',
  FIXED: 'fixed',
  BUTTON: 'button' as const,
  SUBMIT: 'submit' as const,
  RESET: 'reset' as const,
  INDETEMINATE: 'indeterminate' as const,
};

export const USER_MESSAGES = {
  SOMETHING_WENT_WRONG: 'Oops, Something went wrong.',
  TRY_AGAIN: 'Try Again',
  PAGE_NOT_FOUND_TITLE: '404 - Page Not Found',
  GO_TO_HOME_PAGE: 'Go To Home Page',
  PAGE_NOT_FOUND_MESSAGE: 'The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.',
  CATALOGUE: 'Catalogue',
  FILTER: 'Filter',
  ENTER_NAME: 'Enter Name',
  FILTER_BY: 'Filter By',
  BACK_TO_FILTER: 'Back To Filter',
  GO_TO_CATALOGUE: 'Go To Catalogue View',
  COUNT_RESULTS: 'Apply And Count The Results',
  SORT_BY_YEAR_ASC: 'Birth Year: Younger First',
  SORT_BY_YEAR_DESC: 'Birth Year: Older First',
  SORT_BY_NAME_ASC: 'Name: Ascending',
  SORT_BY_NAME_DESC: 'Name: Descending',
  FILTER_MODAL_TITLE: 'Based on those filters',
  FILTER_MODAL_BODY: 'The expected results on the Catalogue view are',
  CATALOGUE_TITLE: 'The Catalouge Of Star Wars People',
  FILTER_TITLE: 'Filter The Star Wars People as you wish',
  FILTERED_BY: '*People filtered by:',
  NAME_INCLUDS: 'Name Includes',
  NO_RESULTS_FOUND: 'No results found. Please try with different filters.',
};

export const PERSON = {
  MASS: 'Mass',
  HEIGHT: 'Height',
  GENDER: 'Gender',
  SKIN_COLOR: 'Skin Color',
  BIRTH_YEAR: 'Birth Year',
  HAIR_COLOR: 'Hair Color',
  EYE_COLOR: 'Eye Color',
  FILM_PLAYED: 'Films Played',
  STARTSHIPS: 'Starships',
  VEHICLES: 'Vehicles',
  SPECIES: 'Species',
  MALE: 'male',
  NAME: 'Name',
  FIELD_NAME: 'name',
  FIELD_MASS: 'mass',
  FIELD_HEIGHT: 'height',
  FIELD_BIRTH_YEAR: 'birth_year',
};
