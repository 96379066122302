import headerStyles from './Header.module.scss';
import { useNavigate } from 'react-router-dom';
import menuItems from '../../utils/MenuIteams';
import { useLocation } from 'react-router-dom';
import MenuItemModel from '../../models/MenuItem.model';
import commonStyles from '../../styles/common.module.scss';

function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;

  return (
    <header className={`${headerStyles.headerContainer} ${commonStyles.zIndexMedium} row`}>
      <div className='col-6'>
        <img src={'./logo.svg'} alt='Logo' />
      </div>
      <div className={`${headerStyles.actionsContainer} col-6`}>
        {menuItems.map((item: MenuItemModel) => (
          <span
            onClick={() => {
              navigate(item.route);
            }}
            className={item.route === currentPath ? headerStyles.active : ''}
            key={item.id}
          >
            {item.label}
          </span>
        ))}
      </div>
    </header>
  );
}

export default Header;
