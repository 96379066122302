import { useNavigate } from 'react-router-dom';
import notFoundStyles from './NotFound.module.scss';
import IButton from '../../components/elements/IButton/IButton';
import { USER_MESSAGES } from '../../constants/constants';
import { RoutesUrl } from '../../router/RoutesUrls';
import Header from '../../components/header/Header';

function NotFound() {
  const navigate = useNavigate();

  return (
    <>
      <Header />
      <section className={notFoundStyles.notFoundContainer}>
        <h1>{USER_MESSAGES.PAGE_NOT_FOUND_TITLE}</h1>
        <p>{USER_MESSAGES.PAGE_NOT_FOUND_MESSAGE}</p>
        <IButton
          label={USER_MESSAGES.GO_TO_HOME_PAGE}
          onClickHandler={() => {
            navigate(RoutesUrl.Catalogue);
          }}
        />
      </section>
    </>
  );
}

export default NotFound;
