import { ERRORS } from '../constants/constants';
import PersonModel from '../models/Person.model';
import SwapiModel from '../models/Swapi.model';

const fetchData = async (url: string): Promise<SwapiModel> => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      // In case we will get a bad response
      throw new Error(ERRORS.PROMISE_REJECTED);
    }
    return await response.json();
  } catch (error) {
    // In case we will fail to fetch data
    throw error;
  }
};

export const fetchAllPeopleData = async (initialUrl: string): Promise<PersonModel[]> => {
  const results: PersonModel[] = [];
  let nextUrl: string | null = initialUrl;

  while (nextUrl) {
    const data: SwapiModel = await fetchData(nextUrl);
    results.push(...data.results);
    nextUrl = data.next;
  }

  return results;
};
