import { USER_MESSAGES } from '../constants/constants';
import MenuItemModel from '../models/MenuItem.model';
import { RoutesUrl } from '../router/RoutesUrls';

const menuItems: MenuItemModel[] = [
  {
    id: 1,
    label: USER_MESSAGES.CATALOGUE,
    route: RoutesUrl.Catalogue,
  },
  {
    id: 2,
    label: USER_MESSAGES.FILTER,
    route: RoutesUrl.Filter,
  },
];

export default menuItems;
