import { Suspense, lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import NotFound from '../pages/NotFound/NotFound';
import { RoutesUrl } from './RoutesUrls';
import MainLayout from '../layout/MainLayout';
import ILoader from '../components/elements/ILoader/ILoader';

const Catalogue = lazy(() => import('../pages/Catalogue/Catalogue'));
const Filter = lazy(() => import('../pages/Filter/Filter'));

const router = createBrowserRouter([
  {
    path: RoutesUrl.Catalogue,
    element: <MainLayout />,
    errorElement: <NotFound />,
    children: [
      {
        path: RoutesUrl.Catalogue,
        element: (
          <Suspense fallback={<ILoader />}>
            <Catalogue />
          </Suspense>
        ),
      },
      {
        path: RoutesUrl.Filter,
        element: (
          <Suspense fallback={<ILoader />}>
            <Filter />
          </Suspense>
        ),
      },
    ],
  },
]);

export default router;
