import CircularProgress from '@mui/material/CircularProgress';
import { DEFINITION } from '../../../constants/constants';

function ILoader() {
  return (
    <CircularProgress
      variant={DEFINITION.INDETEMINATE}
      disableShrink
      sx={{
        position: DEFINITION.FIXED,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: (theme) => (theme.palette.mode === 'light' ? '#1f2a50' : '#308fe8'),
        animationDuration: '550ms',
      }}
      size={90}
      thickness={8}
    />
  );
}

export default ILoader;
