import { Outlet } from 'react-router-dom';
import { useEffect, useState, useCallback } from 'react';
import { PeopleContext } from '../store/People.context';
import { fetchAllPeopleData } from '../controller/PeopleController';
import ContextModel from '../models/Context.model';
import ErrorFallback from '../components/ErrorFallback/ErrorFallback';
import { ERRORS, USER_MESSAGES } from '../constants/constants';
import Header from '../components/header/Header';
import FiltersModel from '../models/Filters.model';
import ILoader from '../components/elements/ILoader/ILoader';
import ClientConfig from '../config';
import PersonModel from '../models/Person.model';

function MainLayout() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [initialContextValue, setInitialContextValue] = useState<ContextModel>({
    people: [],
    filters: {
      name: '',
      birth_year: [],
      gender: [],
    },
    setFilters: (filters: FiltersModel) => {
      setInitialContextValue((prevInitialContextValue) => ({
        ...prevInitialContextValue,
        filters: filters,
      }));
    },
    setPeople: (people: PersonModel[]) => {
      setInitialContextValue((prevInitialContextValue) => ({
        ...prevInitialContextValue,
        people: people,
      }));
    },
  });
  const [isError, setIsError] = useState<boolean>(false);

  const fetchData = useCallback(async () => {
    //useCallback here to ensure it doesn’t change on every render
    try {
      setIsLoading(true);
      const peopleData: PersonModel[] = await fetchAllPeopleData(ClientConfig.swapiFetchBaseApi);
      setInitialContextValue((prevInitialContextValue) => ({
        ...prevInitialContextValue,
        people: peopleData,
      }));

      setIsError(false);
    } catch (error) {
      console.error(ERRORS.FETCHING_PEOPLE_FAILURE, error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <PeopleContext.Provider value={initialContextValue}>
      <Header />
      {isLoading && <ILoader />}
      {!isLoading && !isError && <Outlet />}
      {!isLoading && isError && <ErrorFallback errorMessage={USER_MESSAGES.SOMETHING_WENT_WRONG} onSubmitButtonHandler={fetchData} />}
    </PeopleContext.Provider>
  );
}

export default MainLayout;
