import { createContext } from 'react';
import ContextModel from '../models/Context.model';

export const PeopleContext = createContext<ContextModel>({
  people: [],
  filters: {
    name: '',
    birth_year: [],
    gender: [],
  },
  setFilters: () => {},
  setPeople: () => {},
});
