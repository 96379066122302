import { USER_MESSAGES } from '../../constants/constants';
import IButton from '../elements/IButton/IButton';
import errorFallbackStyles from './ErrorFallback.module.scss';
interface IProps {
  errorMessage: string;
  onSubmitButtonHandler: () => void;
}
function ErrorFallback(props: IProps) {
  const { errorMessage, onSubmitButtonHandler } = props;
  return (
    <div className={errorFallbackStyles.errorFallBackContainer}>
      <h1>{errorMessage}</h1>
      <IButton label={USER_MESSAGES.TRY_AGAIN} onClickHandler={onSubmitButtonHandler} />
    </div>
  );
}

export default ErrorFallback;
